$locked-color: black;
$unlocked-color: black;

.lock {
    width: 24px;
    height: 21px;

    border: 3px solid;
    border-radius: 5px;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        width: 10px;
        height: 11px;
        bottom: 100%;
        position: absolute;
        left: 50%;
        margin-left: -8px;
        border: 3px solid;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom: 0;
        -webkit-transition: all 0.2 ease-in-out;
        transition: all 0.2 ease-in-out;
    }
    &:after {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -1.5px 0 0 -1.5px;
        -webkit-transition: all 0.2 ease-in-out;
        transition: all 0.2 ease-in-out;
    }

    &:hover {
        &:before {
            height: 10px;
        }
    }
}

/* Unlocked */
.unlocked {
    &:before {
        transform-origin: top left;
        transform: rotateY(-180deg);
        margin-left: -4px;
    }
    &:after {
        background: inherit;
    }
}
